import commonHelper from '@/app/utility/common.helper.utility';
import { ModelSelect } from 'vue-search-select';
import AddLookup from './addLookup';
import AddValidation from './addValidation';
import ModulesList from '../responsibility/searchModule';
export default {
  name: 'LookUp',
  components: {
    ModelSelect,
    AddLookup,
    AddValidation,
    ModulesList
  },
  watch: {
    currentPage: function() {
      this.getLookupList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLookupList();
    }
  },
  data() {
    return {
      lookupId: null,
      showModal: false,
      unsubscribe: null,
      payload: {},
      loader: false,
      showAddLookupModal: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      tab: null,
      childComp: null,
      modules: {
        id: null,
        name: null
      },
      lookupType: null,
      lookupDesc: null,
      lookupMeaning: null,
      codeDesc: null,
      moduleList: [],
      lookupList: [],
      lookupFields: [
        {
          key: 'lookup_type_desc',
          label: 'Type'
        },
        {
          key: 'lookup_type_desc',
          label: 'Type Desc'
        },
        {
          key: 'lookup_code'
        },
        {
          key: 'lookup_code_meaning',
          label: 'Code Meaning'
        },
        {
          key: 'lookup_code_desc',
          label: 'Code Description'
        }
      ]
    };
  },
  mounted() {
    this.getLookupList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.lookupId = null;
          this.openShowModal(true, 'AddLookup');
          // this.showAddLookupModal = true;
        }
        if (
          actionName === 'download' &&
          !this.showModal &&
          !this.showAddLookupModal
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'lookup/getLookupList',
            'lookup',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      } else if (tab === 'AddLookup') {
        return (this.childName = 'Add Lookup');
      }
    },
    getLookupList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.modules.id,
        lookup_type: this.lookupType,
        lookup_type_desc: this.lookupDesc,
        lookup_code_meaning: this.lookupMeaning,
        lookup_code_desc: this.codeDesc
      };
      this.loader = true;
      this.$store
        .dispatch('lookup/getLookupList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.lookupList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openShowModal(flag, component) {
      this.tab = component;
      // this.childName(this.tab);
      this.showModal = flag;
      this.setChildName(this.tab);
    },
    childName(tab) {
      this.childComp =
        tab === 'LE'
          ? 'Legal Entity'
          : tab === 'ModulesList'
          ? 'Module'
          : tab === 'FlexfieldMaster'
          ? 'Flexfield Master'
          : tab === 'Flexfield'
          ? 'Flexfield'
          : 'Code Combination Details';
      return this.childComp;
    },
    selectedRow(moduleData) {
      if (this.tab === 'ModulesList') {
        this.modules = {
          id: moduleData.module_id,
          name: moduleData.module_name
        };
      }
      this.showModal = false;
    },
    rowSelected(item) {
      this.lookupId = item.lookup_hdr_id;
      this.openShowModal(true, 'AddLookup');
    },
    hideAddLookUpModal() {
      this.showAddLookupModal = false;
    },
    clearFilters() {
      this.modules = {
        id: null,
        name: null
      };
      this.lookupType = null;
      this.lookupDesc = null;
      this.lookupMeaning = null;
      this.codeDesc = null;
      this.getLookupList();
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
