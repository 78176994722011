import { ModelSelect } from 'vue-search-select';
export default {
  name: 'AddValidation',
  components: {
    ModelSelect
  },
  props: ['valiDateAttrValue', 'lookupType', 'lookupHdrId'],
  data() {
    return {
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      validateModal: false,
      loader: false,
      attribute: null,
      attributeName: { value: null, text: null },
      attributeList: [],
      status: { value: null, text: null },
      statusList: [],
      validateForm: {
        lookup_hdr_id: null,
        lookup_val_rule_id: 0,
        attribute: null,
        active: false,
        rule_select: null,
        rule_from: null,
        rule_where: null,
        rule_fetch: null
      }
    };
  },
  mounted() {
    if (this.lookupHdrId !== 0) {
      this.getValidationDetails();
    }
  },
  methods: {
    validateData() {
      const payload = {
        query_fetch: this.validateForm.rule_fetch,
        query_from: this.validateForm.rule_from,
        query_select: this.validateForm.rule_select,
        query_where: this.validateForm.rule_where
      };
      this.loader = true;
      this.$store
        .dispatch('lookup/verifyLookupQuery', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.$emit('validateData', this.validateForm);
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getValidationDetails() {
      const payload = {
        lookup_hdr_id: this.lookupHdrId,
        attribute: this.valiDateAttrValue
      };
      this.loader = true;
      this.$store
        .dispatch('lookup/getValidation', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data) {
            this.validateForm = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
