import { ModelSelect } from 'vue-search-select';
import ValidateLookup from '../addValidation';
import ModulesList from '../../responsibility/searchModule';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
// import { format } from 'date-fns';
export default {
  name: 'AddEmployee',
  components: {
    ModelSelect,
    ValidateLookup,
    ModulesList,
    DatePicker
  },
  props: ['lookupId'],
  data() {
    return {
      valiDateAttrValue: null,
      attributeNum: null,
      showModuleModal: false,
      unsubscribe: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      validateModal: false,
      loader: false,
      num: [],
      valueSetList: [],
      valueSetFields: [
        {
          key: 'value_code'
        },
        {
          key: 'value_meaning'
        },
        {
          key: 'description'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'sequence'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      selectedModule: {
        id: null,
        name: null
      },
      lookupForm: {
        lookup_hdr_id: 0,
        lookup_type: null,
        lookup_type_desc: null,
        module_id: null,
        module_name: null,
        hdr_additional_info: null,
        attribute_1_label: null,
        attribute_2_label: null,
        attribute_3_label: null,
        attribute_4_label: null,
        attribute_7_label: null,
        attribute_5_label: null,
        attribute_6_label: null,
        attribute_9_label: null,
        attribute_8_label: null,
        attribute_10_label: null
      },
      attributes: [
        {
          active: false,
          attribute: null,
          lookup_hdr_id: 0,
          lookup_val_rule_id: 0,
          rule_fetch: null,
          rule_from: null,
          rule_select: null,
          rule_where: null
        }
      ],
      lookupDetailForm: {
        end_date: null,
        start_date: null,
        start_end_date: null,
        active: false,
        lookup_code_desc: null,
        sequence: 0,
        lookup_code: null,
        lookup_code_meaning: null,
        lookup_hdr_id: null,
        dtl_additional_info: null,
        lookup_dtl_id: 0,
        attribute_1: null,
        attribute_4: null,
        attribute_5: null,
        attribute_3: null,
        attribute_2: null,
        attribute_7: null,
        attribute_8: null,
        attribute_9: null,
        attribute_6: null,
        attribute_10: null
      },
      validateFormData: null
    };
  },
  mounted() {
    this.getLookupDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditLookupType();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.lookupHdrId});
        }

      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      } 
      },
    addEditLookupType() {
      const payload = {
        attribute_1_label: this.lookupForm.attribute_1_label,
        attribute_2_label: this.lookupForm.attribute_2_label,
        attribute_3_label: this.lookupForm.attribute_3_label,
        attribute_4_label: this.lookupForm.attribute_4_label,
        attribute_5_label: this.lookupForm.attribute_5_label,
        attribute_6_label: this.lookupForm.attribute_6_label,
        attribute_7_label: this.lookupForm.attribute_7_label,
        attribute_8_label: this.lookupForm.attribute_8_label,
        attribute_9_label: this.lookupForm.attribute_9_label,
        attribute_10_label: this.lookupForm.attribute_10_label,
        hdr_additional_info: this.lookupForm.hdr_additional_info,
        lookup_code_desc: this.lookupForm.lookup_code_desc,
        lookup_hdr_id: this.lookupForm.lookup_hdr_id,
        lookup_type: this.lookupForm.lookup_type,
        module_id: this.selectedModule.id
      };
      this.loader = true;
      this.$store
        .dispatch('lookup/addEditLookup', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.$emit('updateList');
            this.editMode = false;
            this.isSuccess = true;
            this.lookupForm.lookup_hdr_id = response.data.data.lookup_hdr_id;
            if (this.validateFormData) {
              this.addEditValidation(response.data.data.lookup_hdr_id);
            }
            this.addEditLookupDetails(response.data.data.lookup_hdr_id);
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditValidation(lookupHdrId) {
      this.loader = true;
      const payload = {
        active: false,
        attribute: this.validateFormData.attribute,
        lookup_hdr_id: lookupHdrId,
        lookup_val_rule_id: this.validateFormData.lookup_val_rule_id,
        rule_fetch: this.validateFormData.rule_fetch,
        rule_from: this.validateFormData.rule_from,
        rule_select: this.validateFormData.rule_select,
        rule_where: this.validateFormData.rule_where
      };
      this.$store
        .dispatch('lookup/addEditValidation', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditLookupDetails(lookupHdrId) {
      if (
        this.lookupDetailForm.lookup_code &&
        this.lookupDetailForm.lookup_code_meaning
      ) {
        const payload = {
          active: this.lookupDetailForm.active,
          attribute_1: this.lookupDetailForm.attribute_1,
          attribute_2: this.lookupDetailForm.attribute_2,
          attribute_3: this.lookupDetailForm.attribute_3,
          attribute_4: this.lookupDetailForm.attribute_4,
          attribute_5: this.lookupDetailForm.attribute_5,
          attribute_6: this.lookupDetailForm.attribute_6,
          attribute_7: this.lookupDetailForm.attribute_7,
          attribute_8: this.lookupDetailForm.attribute_8,
          attribute_9: this.lookupDetailForm.attribute_9,
          attribute_10: this.lookupDetailForm.attribute_10,
          dtl_additional_info: this.lookupDetailForm.active,
          lookup_code: this.lookupDetailForm.lookup_code,
          lookup_code_desc: this.lookupDetailForm.lookup_code_desc,
          lookup_code_meaning: this.lookupDetailForm.lookup_code_meaning,
          lookup_dtl_id: this.lookupDetailForm.lookup_dtl_id,
          lookup_hdr_id: lookupHdrId,
          sequence: this.lookupDetailForm.sequence,
          start_date: commonHelper.formattedDate(this.lookupDetailForm.start_end_date[0]),
          end_date: commonHelper.formattedDate(this.lookupDetailForm.start_end_date[1]),
        
        };
        this.loader = true;
        this.$store
          .dispatch('lookup/addEditLookupDetails', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getLookupDetails() {
      if (this.lookupId || this.lookupForm.lookup_hdr_id) {
        const lookupHdrId = this.lookupId
          ? this.lookupId
          : this.lookupForm.lookup_hdr_id;
        this.loader = true;
        this.$store
          .dispatch('lookup/getLookupById', lookupHdrId)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              const data = response.data.data;
              this.lookupForm = data;
              this.selectedModule.name = data.module_name;
              this.selectedModule.id = data.module_id;
              this.getLookupTypeDetails(lookupHdrId);
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getLookupTypeDetails(lookupHdrId) {
      this.loader = true;
      this.$store
        .dispatch('lookup/getLookupTypeDetails', lookupHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data !== null) {
            const data = response.data.data;
            this.lookupDetailForm.start_end_date = [new Date(data.start_date) , new Date (data.end_date)];
            this.lookupDetailForm = data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validateData(data) {
      this.lookupForm[`attribute_${this.attributeNum}_label`] = data.attribute;
      this.validateModal = false;
      this.validateFormData = data;
    },
    showValidateModal(flag, num) {
      this.valiDateAttrValue = this.lookupForm[`attribute_${num}_label`];
      this.attributeNum = num;
      this.validateModal = flag;
    },
    showHideModuleModal(flag) {
      this.showModuleModal = flag;
    },
    moduleData(item) {
      this.selectedModule = {
        id: item.module_id,
        name: item.module_name
      };
      this.showModuleModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
